<template>
  <router-view v-if="loadingComplete"></router-view>
</template>

<script>
import { UPDATE_CLIENT_INFO } from "@/core/services/store/core/client.module";
import { ADD_MENU_TYPE } from "@/core/services/store/system/menu.module";

export default {
  name: "Client",

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 2
      }
    };
  },

  mounted() {
    this.preloadClient();
    this.setMenuType();
  },

  methods: {
    preloadClient() {
      let clientIdentifier = window.location.host.split('.')[0];
      let payload = {
        clientId: clientIdentifier
      };
      this.$store
        .dispatch(UPDATE_CLIENT_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },
    setMenuType() {
      this.$store.dispatch(ADD_MENU_TYPE, "client").then(() => {
        this.pageLoader.componentsCompleted++;
      });
    }
  },

  computed: {
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
